<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col lg="8">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('recipientAddress') }} : <span class="text-danger">*</span>
              </h3>
            </div>
            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-fullname"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('firstName') + '-' + $t('lastName') }}
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="full name"
                      rules="required"
                    >
                      <b-form-input
                        id="h-fullname"
                        v-model="fullnamenew"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-address"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('address') }}
                    </template>
                    <b-form-textarea
                      id="h-address"
                      v-model="addressnew"
                      type="text"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-province"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('province') }}
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="product type"
                      rules="required"
                    >
                      <v-select
                        id="h-province"
                        v-model="selectedProvince"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        label="province_name"
                        :reduce="ele => ele.province_id"
                        :options="provinceOptions"
                        class="w-100 mr-1"
                        @input="updateDistricts"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-district"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('district') }}
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="product type"
                      rules="required"
                    >
                      <v-select
                        id="h-district"
                        v-model="selectedDistrict"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        label="district_name"
                        :reduce="ele => ele.district_id"
                        :options="filteredDistricts"
                        class="w-100 mr-1"
                        @input="updateSubDistricts"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-subdistrict"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('subdistrict') }}
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="product type"
                      rules="required"
                    >
                      <v-select
                        id="h-subdistrict"
                        v-model="selectedSubDistrict"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        label="sub_district_name"
                        :reduce="ele => ele.tumbon_id"
                        :options="filteredSubDistricts"
                        class="w-100 mr-1"
                        @input="updatePostalcode"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-parcel-code"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('postalCode') }}
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="parcel code"
                      rules="required"
                    >
                      <b-form-input
                        id="h-parcel-code"
                        v-model="postalCode"
                        :state="errors.length > 0 ? false:null"
                        label="zipcode"
                        :disabled="postalCode"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-purchase-order"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('mobileNumber') }}
                    </template>

                    <b-form-input
                      id="h-purchase-order"
                      v-model="telnumber"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-purchase-order"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label />

                    <button
                      class="btn btn-primary btn-sm"
                      @click="$bvModal.show('modal-receive')"
                    >
                      {{ $t('chooseRecipientAddress') }}
                    </button>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-customerCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('deliveryType') }}
                    </template>

                    <div>
                      <b-form-radio
                        v-model="transport"
                        name="some-radio9"
                        :value="1"
                        class="custom-control-primary mb-1"
                        @change="transportsnew(transport)"
                      >
                        {{ $t('pickupTimingNote') }}
                      </b-form-radio>

                      <b-form-radio
                        v-model="transport"
                        name="some-radio9"
                        :value="2"
                        class="custom-control-primary mb-1"
                        @change="transportsnew(transport)"
                      >
                        {{ $t('deliveryCompanyPrice') }}
                      </b-form-radio>

                      <b-form-radio
                        v-model="transport"
                        name="some-radio9"
                        :value="3"
                        class="custom-control-primary"
                        @change="transportsnew(transport)"
                      >
                        {{ $t('privateCarrierNotice') }}
                      </b-form-radio>

                      <div
                        v-if="transport === 3"
                        class="ml-2 mt-50"
                      >
                        <b-form-radio
                          v-model="transports"
                          name="check-type"
                          :value="4"
                          class="custom-control-primary mb-50"
                          @change="transportsnew(transports)"
                        >
                          {{ $t('shippingFeeOrigin') }}
                        </b-form-radio>

                        <b-form-radio
                          v-model="transports"
                          name="check-type"
                          :value="5"
                          class="custom-control-primary"
                          @change="transportsnew(transports)"
                        >
                          {{ $t('payDestinationShipping') }}
                        </b-form-radio>

                        <validation-provider
                          #default="{ errors }"
                          name="product type"
                          rules="required"
                        >
                          <v-select

                            id="some-radio9"
                            v-model="productType"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :state="errors.length > 0 ? false:null"
                            label="name"
                            :options="checktransports"
                            class="w-50 mt-50"
                            :placeholder=" '-'+ $t('pleaseSelect')+'-' "
                            @input="inputtransports()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                  </b-form-group>

                  <hr class="my-2 border-dotted">
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-delivery"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('senderAddress') }}
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="product type"
                      rules="required"
                    >
                      <v-select
                        id="h-delivery"
                        v-model="delivery"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        label="label"
                        :reduce="item => item.value"
                        :options="optiondelivery"
                        class="w-100 mr-1"
                        @input="checkdelivery(delivery)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="delivery === 3"
                  md="12"
                >
                  <b-form-group
                    label-for="h-fullname"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('senderName') }}
                    </template>
                    <div>
                      <small class="text-danger">
                        {{ `การกรอกผู้ส่งเองจะถูกคิดค่าบริการ ${general} ฿` }}
                      </small>
                      <validation-provider
                        #default="{ errors }"
                        name="full name"
                        rules="required"
                      >
                        <b-form-input
                          id="h-fullname"
                          v-model="deliveryname"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="delivery ===3"
                  md="12"
                >
                  <b-form-group
                    label-for="h-address"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('address') }}
                    </template>
                    <b-form-textarea
                      id="h-address"
                      v-model="deliveryaddress"
                      type="text"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="delivery ===3"
                  md="12"
                >
                  <b-form-group
                    label-for="h-keyword"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('senderNumberContact') }}
                    </template>

                    <b-form-input
                      id="h-keyword"
                      v-model="deliverykeyword"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-customerCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('wantReceipt') }}?
                    </template>

                    <div class="mt-25">
                      <b-form-checkbox
                        v-model="receipt"
                        name="some-radio9"
                        class="custom-control-primary"
                        @input="checkreceipt(receipt)"
                      >
                        {{ $t('wantReceipt') }}
                      </b-form-checkbox>

                      <button
                        v-if="receipt === true"
                        class="btn-select"
                        @click="$bvModal.show('modal-receive-amend')"
                      >

                        <span
                          v-if="itemsTax"
                        >

                          {{ $t('name') }} {{ itemsTax.texfullname }} <br>
                          {{ $t('type') }} {{ checktypetex(itemsTax.typetex) }}<br>
                          {{ $t('TaxID') }} {{ itemsTax.texnumber }}<br>
                          {{ $t('address') }} {{ itemsTax.address }}<br>
                          {{ $t('mobileNumber') }} {{ itemsTax.telephone }}<br>
                          <img
                            v-if="itemsTax.filetex"
                            :src="GetImg('filetex',itemsTax.filetex)"
                            accept=".jpg, .png, .jpeg"
                            alt="รูปภาพ"
                            class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                          >

                        </span>

                        <span v-else>
                          {{ $t('taxpayerInfo') }}
                        </span>
                      </button>
                    </div>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-checkProduct"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                    responsive="sm"
                  >
                    <template v-slot:label>
                      {{ $t('promotion') }}
                    </template>

                    <!-- && arr.id_delivery == productType._id -->
                    <div v-if="itempro">
                      {{ itempro.name }}
                      <feather-icon
                        icon="Edit3Icon"
                        class="mr-50 text-primary cursor-pointer"
                        @click="$bvModal.show('modal-promotion')"
                      />
                      <feather-icon
                        class="text-danger cursor-pointer"
                        icon="TrashIcon"
                        @click="Deletepro(itempro)"
                      />
                    </div>

                    <!-- {{ item.shippingType.find(ele => ele) }} -->
                    <!--  && item.shippingType[index].id_delivery ==  -->
                    <!-- <feather-icon
                          icon="Edit3Icon"
                          class="mr-50 text-primary cursor-pointer"
                          @click="$bvModal.show('modal-promotion')"
                        />
                        <feather-icon
                          class="text-danger cursor-pointer"
                          icon="TrashIcon"
                          @click="Deletepro(item)"
                        /> -->

                    <button
                      class="btn btn-outline-primary"
                      @click="$bvModal.show('modal-promotion')"
                    >
                      {{ $t('choosePromotion') }}
                    </button>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-checkProduct"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('coupon') }}
                    </template>

                    <div
                      v-if="couponitem"
                      class="btn btn-outline-primary"
                    >
                      <span>{{ $t('code') }} {{ couponitem.name }} </span>
                      <feather-icon
                        class="text-danger cursor-pointer"
                        icon="TrashIcon"
                        @click="Deletecoupon(couponitem)"
                      />
                      <!-- <span>โค้ด {{ couponitem.name }}</span><br>
                        <span>รายละเอียด {{ couponitem.description }}</span><br>
                        <span>ส่วนลด {{ couponitem.discount }}</span> -->

                    </div>
                    <div v-else>
                      <button
                        class="btn btn-outline-primary"
                        @click="$bvModal.show('modal-coupon')"
                      >
                        {{ $t('selectCoupon') }}
                      </button>
                    </div>

                  </b-form-group>
                </b-col>

              </b-row>
            </div>
          </div>
        </b-col>

        <b-col lg="4">
          <div class="card">
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0">
                  {{ $t('amountDue') }}
                </h3>

                <b-form-checkbox
                  v-model="payNow"
                  name="some-radio9"
                  :value="1"
                  class="custom-control-primary"
                >
                  {{ $t('payNow') }}
                </b-form-checkbox>
              </div>

            </div>

            <div class="p-2">
              <table class="w-100">
                <tbody>
                  <tr class="mb-1">
                    <td class="w-25">
                      <p class="mb-0 font-weight-bolder">
                        {{ $t('customerBalance') }}
                      </p>
                    </td>
                    <td class="w-75">
                      <p class="mb-2 font-weight-bolder text-success ">
                        {{ Commas(sumuser = paymentuser.balance) }} ฿
                      </p>
                    </td>
                  </tr>

                  <tr class="mb-1">
                    <td class="w-25">
                      <p class="mb-0 font-weight-bolder">
                        {{ $t('importFee') }}
                      </p>
                    </td>
                    <td class="w-75">
                      <p class="mb-0">
                        {{ Commas(sumitem) }} ฿
                      </p>
                    </td>
                  </tr>

                  <tr class="mb-1">
                    <td class="w-25">
                      <p class="mb-0 font-weight-bolder">
                        {{ $t('deliveryBy') }}
                      </p>
                    </td>
                    <td class="w-75">
                      <p class="mb-0">
                        {{ bydelivery ? bydelivery: '-' }}
                      </p>
                    </td>
                  </tr>

                  <tr class="mb-1">
                    <td class="w-25">
                      <p class="mb-0 font-weight-bolder">
                        {{ $t('serviceFee') }}
                      </p>
                    </td>
                    <td class="w-75">
                      <p class="mb-0">
                        {{ deliverypay ? Commas(deliverypay): 0 }} ฿
                      </p>
                    </td>
                  </tr>

                  <tr class="mb-1">
                    <td class="w-25">
                      <p class="mb-0 font-weight-bolder">
                        {{ $t('shippingFee') }}
                      </p>
                    </td>
                    <td class="w-75">
                      <p class="mb-0">
                        {{ sumshipping ? Commas(sumshipping): 0 }} ฿
                      </p>
                    </td>
                  </tr>

                  <tr class="mb-1">
                    <td class="w-25">
                      <p class="mb-0 font-weight-bolder">
                        {{ $t('paymentType') }}
                      </p>
                    </td>
                    <td class="w-75">
                      <p class="mb-0">
                        {{ $t('deductBalance') }}
                      </p>
                    </td>
                  </tr>

                  <tr class="mb-1">
                    <td class="w-25">
                      <h4 class="mb-0 font-weight-bolder">
                        {{ $t('totalAmount') }}
                      </h4>
                    </td>
                    <td class="w-75">
                      <h4 class="mb-0 font-weight-bolder text-success">
                        {{ Commas(sumpay =(sumitem + deliverypay + sumshipping)) }} ฿
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </table>

              <b-form-group
                label-for="h-remark"
                label="หมายเหตุ"
                class="mt-1"
              >
                <b-form-textarea
                  id="h-remark"
                  v-model="remark"
                  type="text"
                />
              </b-form-group>

              <button
                class="btn btn-gradient-primary w-100 mt-1"
                :disabled="isDisabled"
                @click="Submit()"
              >
                {{ $t('saveData') }} <i class="fas fa-save" />
              </button>
            </div>
          </div>
        </b-col>

        <b-col lg="12">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('parcelCodeList') }}
              </h3>
            </div>

            <div class="table-container">
              <table
                class="styled-table mt-n25"
              >
                <thead>
                  <tr>
                    <th>
                      {{ $t('lotSequence') }}
                    </th>

                    <th>
                      {{ $t('parcelCode') }}
                    </th>

                    <!-- <th>
                      {{ $t('orderList') }}
                    </th> -->

                    <th>
                      {{ $t('arrivedThailand') }}
                    </th>

                    <th>
                      {{ $t('quantity') }}
                    </th>

                    <th>
                      {{ $t('weight') }}
                    </th>

                    <th>
                      {{ $t('width') }}
                    </th>

                    <th>
                      {{ $t('height') }}
                    </th>

                    <th>
                      {{ $t('length') }}
                    </th>

                    <th>
                      {{ $t('cubic') }}
                    </th>

                    <th>
                      {{ $t('type') }}
                    </th>

                    <th>
                      {{ $t('serviceFee') }}
                    </th>

                    <th>
                      {{ $t('pricePerKilo') }}
                    </th>

                    <th>
                      {{ $t('pricePerCubic') }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in Arritems"
                    :key="index"
                  >
                    <td style="width: 150px">
                      <i
                        v-if="item.transportType === 1"
                        class="fas fa-truck-moving"
                      />
                      <i
                        v-if="item.transportType === 2"
                        class="fas fa-ship"
                      />

                      {{ item.lot_name }} / {{ item.lot_order }}

                    </td>
                    <td>{{ item.postnumber }}</td>
                    <!-- <td>{{ item.tag_id }}</td> -->
                    <td>{{ item.come_thaiwarehouse }}</td>
                    <td>{{ Commas(item.product_amount) }}</td>
                    <td>{{ Commas(item.weight*item.product_amount) }}</td>
                    <td>{{ Commas(item.width) }}</td>
                    <td>{{ Commas(item.height) }}</td>
                    <td>{{ Commas(item.long) }}</td>
                    <td>{{ CommasCue(item.charge_cue*item.product_amount) }}</td>
                    <td style="width: 50px">
                      {{ item.product_type.pro_initial }}
                    </td>
                    <td>{{ other = (item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee) }}</td>
                    <td><span v-if="item.discount && item.charge_per_kilo === item.charge_per_save">
                          {{ item.charge_per_kilo }}<span class="text-danger">-{{ item.discount }}% = {{ item.amount_discount }}
                          </span>
                        </span>
                      <span v-else>{{ item.charge_per_kilo === item.charge_per_save ? Commas(item.charge_per_save): '-' }}</span>
                    </td>
                    <td><span v-if="item.discount && item.charge_per_cue === item.charge_per_save">
                          {{ item.charge_per_cue }}<span class="text-danger">-{{ item.discount }}% = {{ item.amount_discount }}
                          </span>
                        </span>
                      <span v-else>{{ item.charge_per_cue === item.charge_per_save ? Commas(item.charge_per_save): '-' }}</span>
                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      colspan="11"
                      class="text-right font-weight-bolder"
                    >
                      {{ $t('total') }}
                    </td>
                    <td
                      colspan="3"
                      class="text-right"
                    >
                      <span v-if="couponitem">
                        <span style="text-decoration: line-through; color: red;">{{ Commas(sumpayment = Arritems.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                          (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)) }} ฿</span>
                        {{ Commas(Arritems.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                          (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0) - couponitem.discount) }} ฿
                      </span>
                      <span v-else>
                        {{ Commas(sumitem = Arritems.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                          (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)) }} ฿
                        <!-- {{ Commas(sumitem = Arritems.reduce((total,item) => total + item.charge_per_save +item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)) }} ฿ -->

                      </span>

                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="11"
                      class="text-right font-weight-bolder"
                    >
                      {{ $t('discountCoupon') }}
                    </td>

                    <td
                      colspan="3"
                      class="text-right"
                    >
                      <span
                        v-if="couponitem"
                        style="color: red;"
                      >
                        {{ couponitem.discount }}
                      </span>
                      <span v-else>
                        -
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan="11"
                      class="text-right font-weight-bolder"
                    >
                      {{ $t('thaiWarehouseFee') }}
                    </td>

                    <td
                      colspan="3"
                      class="text-right"
                    >
                      {{ deliverypay ? deliverypay: 0 }} ฿
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan="11"
                      class="text-right font-weight-bolder"
                    >
                      <small class="text-danger">{{ $t('estimatedCostNote') }}</small>   {{ $t('domesticDeliveryFee') }}
                    </td>

                    <td
                      colspan="3"
                      class="text-right"
                    >

                      <span v-if="itemshipping.delivery < deliverymoney">
                        <span style="text-decoration: line-through; color: red;">{{ deliverymoney +`฿` }}</span>
                        {{ sumshipping = itemshipping.delivery }} ฿
                      </span>
                      <span v-else>
                        {{ deliverymoney ? sumshipping=deliverymoney: 0 }} ฿
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan="11"
                      class="text-right font-weight-bolder"
                    >
                      {{ $t('netAmount') }}
                    </td>

                    <td
                      colspan="3"
                      class="text-right h3"
                    >
                      {{ Commas(sumitem + deliverypay + sumshipping) }} ฿
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- Modal ที่อยู่ผู้รับ -->
      <b-modal
        id="modal-receive"
        :title="$t('chooseRecipientAddress')"
        size="lg"
        @ok="addAddress()"
      >
        <button
          class="btn btn-outline-primary btn-sm"
          @click="EditType()"
        >
          <i class="fal fa-plus" /> {{ $t('addNewAddress') }}
        </button>
        <div class="mt-1">
          <b-table
            responsive="sm"
            :items="getaddress"
            :fields="addrFields"
            show-empty
          >

            <template #cell(set)="data">
              <b-form-radio
                v-model="checktext"
                :value="data.item"
                :unchecked-value="false"
              />

            </template>

            <template #cell(actions)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-50 text-primary cursor-pointer"
                @click="EditType(data.item)"
              />

              <feather-icon
                icon="TrashIcon"
                class="text-danger cursor-pointer"
                @click="Deleteaddress(data.item)"
              />
            </template>
          </b-table>
        </div>

      </b-modal>

      <!-- Modal เพิ่มผู้รับ -->
      <b-modal
        id="modal-receive-add"
        :title="$t('addAddress')"
        @ok="addNewAddress()"
      >
        <validation-observer ref="addNewAddress">
          <b-row>
            <b-col md="12">
              <b-form-group
                label-for="h-fullname"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('firstName') + '-' + $t('lastName') }}
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="full name"
                  rules="required"
                >
                  <b-form-input
                    id="h-fullname"
                    v-model="fullname"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="h-phone"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('mobileNumber') }}
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="phone number"
                  rules="required"
                >
                  <b-form-input
                    id="h-phone"
                    v-model="phoneNum"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="h-address"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('address') }}
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="address"
                  rules="required"
                >
                  <b-form-textarea
                    id="h-address"
                    v-model="address"
                    :state="errors.length > 0 ? false:null"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="h-province"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('province') }}
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="product type"
                  rules="required"
                >
                  <v-select
                    id="h-province"
                    v-model="Province"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :state="errors.length > 0 ? false:null"
                    label="province_name"
                    :reduce="ele => ele.province_id"
                    :options="provinceOptionsnew"
                    class="w-100 mr-1"
                    @input="updateDistrictsnew"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="h-district"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('district') }}
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="district"
                  rules="required"
                >
                  <v-select
                    id="h-district"
                    v-model="district"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :state="errors.length > 0 ? false:null"
                    label="district_name"
                    :reduce="ele => ele.district_id"
                    :options="filteredDistrictsnew"
                    class="w-100 mr-1"
                    @input="updateSubDistrictsnew"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="h-subdistrict"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('subdistrict') }}
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="subdistrict"
                  rules="required"
                >
                  <v-select
                    id="h-subdistrict"
                    v-model="subdistrict"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :state="errors.length > 0 ? false:null"
                    label="sub_district_name"
                    :reduce="ele => ele.tumbon_id"
                    :options="filteredSubDistrictsnew"
                    class="w-100 mr-1"
                    @input="updatePostalcodenew"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="h-parcel-code"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('postalCode') }}
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="parcel code"
                  rules="required"
                >
                  <b-form-input
                    id="h-parcel-code"
                    v-model="postal"
                    :state="errors.length > 0 ? false:null"
                    label="zipcode"
                    :disabled="postal"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="h-set-default"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('setAddress') }}
                </template>

                <b-form-checkbox
                  v-model="setDefault"
                  name="some-radio9"
                  :value="1"
                  class="custom-control-primary"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-modal>

      <!-- Modal เลือกข้อมูลผู้เสียภาษี หรือ ใบรับรองบริษัท -->
      <b-modal
        id="modal-receive-amend"
        :title="$t('taxpayerInfo')"
        size="lg"
        @ok="additemtex()"
      >
        <button
          class="btn btn-outline-primary btn-sm"
          @click="EditTex()"
        >
          <i class="fal fa-plus" /> {{ $t('addInfo') }}
        </button>
        <div class="mt-1">
          <b-table
            responsive="sm"
            :items="itemsTaxpayer"
            :fields="Fieldstex"
            show-empty
          >

            <template #cell(set)="data">
              <b-form-checkbox
                v-model="isSelected"
                :value="data.item"
                :unchecked-value="false"
              />
            </template>
            <template #cell(filetex)="data">
              <img
                v-if="data.item.filetex"
                :src="GetImg('filetex', data.item.filetex)"
                accept=".jpg, .png, .jpeg"
                alt="รูปภาพ"
                class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                @click="showPicture(data.item.filetex)"
              >
              <vue-image-lightbox-carousel
                ref="lightbox"
                :show="showLightbox"
                :images="images"
                :show-caption="false"
                @close="closeBox()"
              />
            </template>
            <template #cell(typetex)="data">
              {{ checktypetex(data.item.typetex) }}
            </template>

            <template #cell(actions)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-50 text-primary cursor-pointer"
                @click="EditTex(data.item)"
              />

              <feather-icon
                icon="TrashIcon"
                class="text-danger cursor-pointer"
                @click="Deletetex(data.item)"
              />
            </template>
          </b-table>
        </div>

      </b-modal>

      <!-- Modal เพื่อมข้อมูลผู้เสียภาษี -->
      <b-modal
        id="modal-receive-add-amend"
        :title="$t('addTaxUser')"
        @ok.prevent="addNewTex()"
      >
        <validation-observer ref="addNewtexse">
          <b-row>
            <b-col md="12">
              <b-form-group
                label-for="h-typetex"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  <span class="text-danger">*</span> {{ $t('type') }}
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="full name"
                  rules="required"
                >
                  <div class="demo-inline-spacing mt-n1">
                    <b-form-radio
                      v-model="typetex"
                      name="some-radios"
                      :value="1"
                    >
                      {{ $t('Individual') }}
                    </b-form-radio>
                    <b-form-radio
                      v-model="typetex"
                      name="some-radios"
                      :value="2"
                    >
                      {{ $t('legalEntity') }}
                    </b-form-radio>
                  </div>
                  <small class="text-danger">{{ errors[0] ? $t('requiredField'): '' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label-for="h-texfullname"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  <span class="text-danger">*</span> {{ $t('name') }}
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="full name"
                  rules="required"
                >
                  <b-form-input
                    id="h-texfullname"
                    v-model="texfullname"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] ? $t('requiredField'): '' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="h-addresstex"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  <span class="text-danger">*</span> {{ $t('address') }}
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="addresstex"
                  rules="required"
                >
                  <b-form-textarea
                    id="h-addresstex"
                    v-model="addresstex"
                    :state="errors.length > 0 ? false:null"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] ? $t('requiredField'): '' }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="h-telephone"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  <span class="text-danger">*</span> {{ $t('mobileNumber') }}
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="phone number"
                  rules="required"
                >
                  <b-form-input
                    id="h-telephone"
                    v-model="telephone"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] ? $t('requiredField'): '' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="h-parcel-code"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  <span class="text-danger">*</span> {{ $t('TaxID') }}
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Taxpayer"
                  rules="required"
                >
                  <b-form-input
                    id="h-parcel-code"
                    v-model="Taxpayer"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] ? $t('requiredField'): '' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="h-parcel-code"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  <span class="text-danger">*</span>  {{ $t('taxDocument') }}
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Taxpayer"
                  rules="required"
                >
                  <div>
                    <b-form-file
                      v-model="selectfile"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      multiple
                      @change="filetex"
                    />
                    <img
                      v-if="namefile"
                      :src="GetImg('filetex',namefile)"
                      accept=".jpg, .png, .jpeg"
                      alt="รูปภาพ"
                      class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                      @click="showPicture(namefile)"
                    >
                    <vue-image-lightbox-carousel
                      ref="lightbox"
                      :show="showLightbox"
                      :images="images"
                      :show-caption="false"
                      @close="closeBox()"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-modal>

      <!-- Modal promotion  -->
      <b-modal
        id="modal-promotion"
        :title="$t('choosePromotion')"
        size="lg"
        centered
        ok-title="OK"
        cancel-title="Cancel"
        @ok="submitpro()"
      >
        <validation-observer ref="promotion">
          <b-table
            responsive="sm"
            :items="proItems"
            :fields="proFields"
            show-empty
          >
            <template #cell(set)="data">
              <b-form-radio
                v-model="setDefault"
                :value="data.item"
                :unchecked-value="false"
              />
            </template>
            <template #cell(promotion)="data">
              {{ data.item.name }}
            </template>
            <template #cell(detail)="data">
              {{ data.item.details }}
            </template>
          </b-table>
        </validation-observer></b-modal>

      <!-- Modal coupon -->
      <b-modal
        id="modal-coupon"
        :title="$t('selectCoupon')"
        size="lg"
        centered
        @ok="submitcoupon()"
      >
        <validation-observer ref="coupon">
          <b-table
            responsive="sm"
            :items="itemcoupon"
            :fields="Fieldscoupon"
            show-empty
          >

            <template #cell(setcoupons)="data">
              <b-form-radio
                v-model="couponcheck"
                :value="data.item"
                :unchecked-value="false"
              />
            </template>
            <template #cell(coupons)="data">
              <div style="background-color: rgb(97,201,149); padding: 20px">
                <span /><br>
                <span>{{ $t('code') }} {{ data.item.name }}</span><br>
                <span /><br>
              </div>
            </template>
            <template
              #cell(detailcoupons)="data"
            >
              <!-- style="background-color: blue;" -->
              <div style="background-color: rgb(142,243,206); padding: 20px; ">
                <span>{{ $t('code') }} {{ data.item.name }}</span><br>
                <span>{{ $t('details') }} {{ data.item.description }}</span><br>
                <span>{{ $t('discount') }} {{ data.item.discount }}</span>
              </div>

            </template>

          </b-table></validation-observer>

      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  // BInputGroup,
  // BInputGroupAppend,
  // BFormDatepicker,
  BFormCheckbox,
  BFormRadio,
  BTable,
  BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BFormFile,
    // BInputGroup,
    // BInputGroupAppend,
    // BFormDatepicker,
    BFormCheckbox,
    BFormRadio,
    BTable,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      required,
      email,
      images: [],
      showLightbox: false,

      // addrItems: [
      //   {
      //     id: 1,
      //     set: 'ที่อยู่หลัก',
      //     fullName: 'นาย สมชาย ใจดี',
      //     phoneNum: '089-999-9999',
      //     province: 'กรุงเทพมหานคร',
      //     postalcode: '10220',
      //     address: '123 หมู่ 1 ต.บางเขน อ.เมือง จ.กรุงเทพมหานคร 10220',
      //   },
      // ],
      // proItems: [],

      delivery: 0,
      optiondelivery: [
        { value: 0, label: '-กรุณาเลือก-' },
        { value: 1, label: 'P2 Cargo' },
        { value: 2, label: 'ไม่แสดงราคา' },
        { value: 3, label: 'กรอกใหม่' },
      ],
      payNow: 0,
      receipt: false,
      checkProduct: false,
      customerCode: null,
      parcelCode: null,
      purchaseOrder: null,
      transport: 0,
      keyword: null,
      status: null,
      remark: null,
      amount: 1,
      weight: 0,
      width: 0,
      height: 0,
      long: 0,
      ThinkPerKilo: 0,
      calPriceAuto: true,
      parcelExport: null,
      file: null,
      selectedFiles: [],
      uploadedImages: [],
      imagePreviewUrls: [],
      arritems: [],
      Arritems: this.$route.params.arritems,
      ID: this.$route.params.id,
      paymentuser: [],
      getaddress: [],
      showaddress: [],
      postalCode: null,
      selectedProvince: null,
      selectedDistrict: null,
      selectedSubDistrict: null,
      itemspvc: [],
      idistrict: [],
      iSubdistrict: [],
      izipcode: [],
      subDistrict: [],
      district: null,
      subdistrict: null,
      postal: null,
      setDefault: null,
      fullnamenew: null,
      addressnew: null,
      telnumber: null,
      idaddress: null,
      defaultget: false,
      uidaddress: null,
      district_id: null,
      Province: null,
      transports: 0,
      productType: [],
      rolelist: [],
      deliveryname: null, // ชื่อผู้ส่ง
      deliveryaddress: null, // ที่อยู่ผู้ส่ง
      deliverykeyword: null, // เบอร์ติดต่อผู้ส่ง
      sumitem: null, // ค่านำเข้า
      bydelivery: null, // จัดส่งโดย
      deliverypay: 0, // ค่าบริการ
      deliverymoney: null, // ค่าขนส่ง
      sumuser: null,
      sumpay: null,
      itempro: null,
      itemshipping: [],
      sumshipping: 0,
      selectfile: null,
      namefile: null,
      Taxpayer: null,
      telephone: null,
      addresstex: null,
      texfullname: null,
      typetex: 0,
      itemsTaxpayer: [],
      idtex: null,
      itemsTax: null,
      checktext: null,
      isSelected: null,
      general: 0,
      itemcoupon: [],
      couponitem: null,
      couponcheck: null,
      sumpayment: 0,
      WarehousePrice: null,
      isDisabled: false,

    }
  },
  computed: {
    Fieldstex() {
      return [
        { key: 'set', label: '', thStyle: { width: '5%' } },
        { key: 'texfullname', label: this.$t('name'), thStyle: { width: '20%' } },
        { key: 'address', label: this.$t('address'), thStyle: { width: '20%' } },
        { key: 'telephone', label: this.$t('mobileNumber'), thStyle: { width: '15%' } },
        { key: 'texnumber', label: this.$t('TaxID'), thStyle: { width: '15%' } },
        { key: 'typetex', label: this.$t('customerType'), thStyle: { width: '20%' } },
        { key: 'filetex', label: this.$t('document'), thStyle: { width: '20%' } },
        { key: 'actions', label: this.$t('manage'), thStyle: { width: '10%' } },
      ]
    },
    addrFields() {
      return [
        { key: 'set', label: '', thStyle: { width: '5%' } },
        { key: 'fullname', label: `${this.$t('firstName')}-${this.$t('lastName')}`, thStyle: { width: '20%' } },
        { key: 'tel', label: this.$t('mobileNumber'), thStyle: { width: '20%' } },
        { key: 'province', label: this.$t('province'), thStyle: { width: '15%' } },
        { key: 'postalcode', label: this.$t('postalCode'), thStyle: { width: '10%' } },
        { key: 'address', label: this.$t('address'), thStyle: { width: '20%' } },
        { key: 'actions', label: this.$t('manage'), thStyle: { width: '10%' } },
      ]
    },
    proFields() {
      return [
        { key: 'set', label: '', thStyle: { width: '5%' } },
        { key: 'promotion', label: this.$t('name'), thStyle: { width: '25%' } },
        { key: 'detail', label: this.$t('details'), thStyle: { width: '70%' } },
      ]
    },
    Fieldscoupon() {
      return [
        { key: 'setcoupons', label: '', thStyle: { width: '5%' } },
        { key: 'coupons', label: this.$t('coupon'), thStyle: { width: '25%' } },
        { key: 'detailcoupons', label: this.$t('details'), thStyle: { width: '70%' } },
      ]
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    provinceOptions() {
      return this.itemspvc.map(item => ({
        province_id: item.province_id,
        province_name: item.province_name,
      }))
    },
    filteredDistricts() {
      if (this.selectedProvince) {
        return this.idistrict.filter(ele => (ele.province_id === this.selectedProvince))
          .map(item => ({
            district_id: item.district_id,
            district_name: item.district_name,
            province_id: item.province_id,
          }))
      }
      return this.idistrict.map(item => ({
        district_id: item.district_id,
        district_name: item.district_name,
        province_id: item.province_id,
      }))
    },

    filteredSubDistricts() {
      if (this.selectedDistrict) {
        return this.iSubdistrict.filter(ele => (ele.ampur_id === this.selectedDistrict))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      if (this.selectedProvince) {
        return this.iSubdistrict.filter(ele => (ele.changwat_id === this.selectedProvince))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      return this.iSubdistrict.map(item => ({
        ampur_id: item.ampur_id,
        sub_district_name: item.sub_district_name,
        tumbon_id: item.tumbon_id,
        changwat_id: item.changwat_id,
      }))
    },
    filteredpostalCode() {
      if (this.selectedSubDistrict) {
        return this.izipcode.filter(ele => (ele.tum_id === this.selectedSubDistrict))
          .map(item => ({
            tum_id: item.tum_id,
            zipcode: item.zipcode,
          }))
      }
      return this.izipcode.map(item => ({
        tum_id: item.tum_id,
        zipcode: item.zipcode,
      }))
    },

    filteredDistrictsnew() {
      if (this.Province) {
        return this.idistrict.filter(ele => (ele.province_id === this.Province))
          .map(item => ({
            district_id: item.district_id,
            district_name: item.district_name,
            province_id: item.province_id,
          }))
      }
      return this.idistrict.map(item => ({
        district_id: item.district_id,
        district_name: item.district_name,
        province_id: item.province_id,
      }))
    },
    filteredSubDistrictsnew() {
      if (this.district) {
        return this.iSubdistrict.filter(ele => (ele.ampur_id === this.district))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      if (this.Province) {
        return this.iSubdistrict.filter(ele => (ele.changwat_id === this.Province))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      return this.iSubdistrict.map(item => ({
        ampur_id: item.ampur_id,
        sub_district_name: item.sub_district_name,
        tumbon_id: item.tumbon_id,
        changwat_id: item.changwat_id,
      }))
    },
    provinceOptionsnew() {
      return this.itemspvc.map(item => ({
        province_id: item.province_id,
        province_name: item.province_name,
      }))
    },
    checktransports() {
      if (this.transport === 3 && this.transports === 0) {
        return []
      }
      if (this.transports === 4) {
        return this.rolelist.filter(ele => ele.delivery_source === 1)
      }
      if (this.transports === 5) {
        return this.rolelist.filter(ele => ele.destination === 1)
      } if (this.transport === '3') {
        return this.rolelist
      }
      return this.rolelist

      // if (item === 4) {
      //   return this.rolelist.filter(ele => ele.delivery_source === 1)
      //   // console.log(this.rolelist)
      // }

      // console.log(this.rolelist)
    },

    // filteredpostalCodenew() {
    //   if (this.selectedSubDistrict) {
    //     return this.izipcode.filter(ele => (ele.tum_id === this.selectedSubDistrict))
    //       .map(item => ({
    //         tum_id: item.tum_id,
    //         zipcode: item.zipcode,
    //       }))
    //   }
    //   return this.izipcode.map(item => ({
    //     tum_id: item.tum_id,
    //     zipcode: item.zipcode,
    //   }))
    // },
  },

  // created() {
  //   // ตรวจสอบว่ามีค่า arritems ในพารามิเตอร์ URL หรือไม่
  //   if (this.$route.params.arritems) {
  //     this.arritems = this.$route.params.arritems
  //   }
  // },
  mounted() {
    this.getItem()
    this.getAddress()
    this.getProvince()
    this.getDistrict()
    this.getSubdistrict()
    this.getzipcode()
    this.getproductType()
    this.getTexpay()
    this.getgeneral()
    this.getcoupon()
    this.GetWarehouse()
  },
  methods: {
    showPicture(picture) {
      this.showLightbox = true
      // console.log(picture)
      // eslint-disable-next-line no-shadow
      // picture.forEach(picture => {
      const img = { path: this.GetImg('filetex', picture) }
      this.images.push(img)
      // })
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    async GetWarehouse() {
      try {
        const { data: res } = await this.$http.get('/warehouse/warehouse_show')
        this.WarehousePrice = res
      } catch (err) {
        console.log(err)
      }
    },
    addNewAddress() {
      this.$refs.addNewAddress.validate().then(success => {
        if (success) {
          const formData = {
            uid: this.ID,
            username: this.Arritems[0].username,
            id: this.idaddress,
            fullname: this.fullname,
            tel: this.phoneNum,
            address: this.address,
            subdistrict_id: this.subdistrict,
            subdistrict: '',
            district_id: this.district,
            district: '',
            province_id: this.Province,
            province: '',
            postalcode: this.postal,
            defaultget: this.setDefault,
          }
          if (this.Province) {
            const provi = this.itemspvc.find(ele => (ele.province_id === this.Province))
            formData.province = provi.province_name
          }
          if (this.district) {
            const arr = this.idistrict.find(ele => (ele.district_id === this.district))
            formData.district = arr.district_name
          }
          if (this.subdistrict) {
            const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.subdistrict))
            formData.subdistrict = arr.sub_district_name
          }
          this.$http.post('/Paymentuser/updateAddress', formData)
            .then(() => {
              // this.$bvModal.hide('modal-receive-add')
              this.getAddress()
              this.Success('อัพเดทที่อยู่เรียบร้อย')
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
          //     this.$bvModal.hide('modal-receive-add')
        }
      })
    },
    async getItem() {
      try {
        const params = {
          ID: this.ID,
        }
        const { data: res } = await this.$http.get('/Paymentuser/getBalanceOne', { params })
        this.paymentuser = res
        // console.log(res)
        // .then(response => {
        //   this.paymentuser = response.data
        //   // console.log(response.data)
        // })
        // console.log(obj)
      } catch (e) {
        console.log(e)
      }
      // console.log(this.ID)
      // const Arritems = this.$route.params.arritems
      // console.log(this.Arritems)
    },
    async getAddress() {
      try {
        const params = {
          uid: this.ID,
        }
        const { data: res } = await this.$http.get('/Paymentuser/getAddress', { params })
        this.getaddress = res
        this.getaddress.forEach(ele => {
          if (ele.defaultget === 1) {
            this.fullnamenew = ele.fullname
            this.addressnew = ele.address
            this.telnumber = ele.tel
            this.selectedProvince = ele.province_id
            this.selectedDistrict = ele.district_id
            this.selectedSubDistrict = ele.subdistrict_id
            this.postalCode = ele.postalcode
          }
        })
        // .then(response => {
        //   this.getaddress = response.data
        //   // console.log(this.getaddress)
        //   this.getaddress.forEach(ele => {
        //     if (ele.defaultget === 1) {
        //       this.fullnamenew = ele.fullname
        //       this.addressnew = ele.address
        //       this.telnumber = ele.tel
        //       this.selectedProvince = ele.province_id
        //       this.selectedDistrict = ele.district_id
        //       this.selectedSubDistrict = ele.subdistrict_id
        //       this.postalCode = ele.postalcode
        //     }
        //   })
        //   // console.log(this.getaddress)
        // })
      } catch (e) {
        console.log(e)
      }
    },
    async getcoupon() {
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/Paymentuser/getCoupon', { params })

        const check = this.Arritems.filter(ele => ele.discount)
        if (check.length) {
          this.itemcoupon = []
        } else {
          this.itemcoupon = res
        }
        // console.log(check)
        // this.Arritems.forEach(ele => {
        //   if (!ele.discount) {
        //     this.itemcoupon = res
        //   }
        // })
        // console.log(this.sumitem)
      } catch (e) {
        console.log(e)
      }
    },
    async getTexpay() {
      try {
        const params = {
          uid: this.ID,
        }
        const { data: res } = await this.$http.get('/Paymentuser/getTaxpayer', { params })
        this.itemsTaxpayer = res
      } catch (e) {
        console.log(e)
      }
    },
    additemtex() {
      // this.itemsTax = null
      // const Check = this.itemsTaxpayer.filter(ele => ele.isSelected)
      this.itemsTax = this.isSelected
    },
    Submit() {
      this.isDisabled = true
      if (this.sumpay > this.sumuser) {
        this.Alert(false, 'ยอดเงินในระบบไม่พอ')
        this.isDisabled = false
      } else if (this.transport === 0) {
        this.Alert(false, 'เลือกวิธีจัดส่ง')
        this.isDisabled = false
      } else if (this.delivery === 0) {
        this.Alert(false, 'เลือกผู้ส่ง')
        this.isDisabled = false
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
          // const subroleJs = this.subrole.filter(item => item.status)
          // const subrole = subroleJs.map(item => item.level)
            let differences = 0
            if (this.Arritems) {
              const sum = this.Arritems.reduce((total, item) => total + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee + item.charge_per_save, 0)
              differences = Number(sum) - Number(this.sumitem)
            }
            let itemdelivery = []
            if (this.delivery === 1) {
              const delivery = {
                id: 1,
                name: 'P2 Cargo',

              }
              itemdelivery = delivery
            }
            if (this.delivery === 2) {
              const delivery = {
                id: 2,
                name: 'ไม่แสดงราคา',
              }
              itemdelivery = delivery
            }
            if (this.delivery === 3) {
              const delivery = {
                id: 3,
                name: this.deliveryname,
                address: this.deliveryaddress,
                tel: this.deliverykeyword,
              }
              itemdelivery = delivery
            }
            let itemtype = []
            let typeout = null
            let itemstatus = null
            if (this.transport === 1) {
              const arr = {
                id: 1,
                pro_name: 'รับสินค้าเอง',
                paydelivery: 'จ่ายค่าขนส่งต้นทาง',
              }
              itemtype = arr
              typeout = 1
            }
            if (this.transport === 2) {
              const arr = {
                id: 2,
                pro_name: 'บริษัทจัดส่ง 690 บาท',
                paydelivery: 'จ่ายค่าขนส่งต้นทาง',
              }
              itemtype = arr
              itemstatus = 1
            }
            if (this.transport === 3) {
              if (this.transports === 4) {
                const arr = {
                  id: 3,
                  pro_name: `${this.productType.name}`,
                  paydelivery: 'จ่ายค่าขนส่งต้นทาง',
                }
                itemtype = arr
                itemstatus = 1
              }
              if (this.transports === 5) {
                const arr = {
                  id: 3,
                  pro_name: `${this.productType.name}`,
                  paydelivery: 'จ่ายค่าขนส่งปลายทาง',
                }
                itemtype = arr
                itemstatus = 1
              }
            }
            let arritem = null
            if (this.receipt && this.itemsTax) {
              arritem = this.itemsTax
            }
            const obj = {
              uid: this.ID,
              payNow: this.payNow,
              postnumberlot: this.Arritems,
              address: {
                fullname: this.fullnamenew,
                tel: this.telnumber,
                address: this.addressnew,
                province_id: this.selectedProvince,
                province_name: '',
                district_id: this.selectedDistrict,
                district: '',
                subdistrict_id: this.selectedSubDistrict,
                subdistrict: '',
                postalcode: this.postalCode,
              },
              typedelivery: itemtype,
              delivery: itemdelivery,
              payment: this.sumpay,
              remark: this.remark,
              itemnumber: 1,
              username: this.Arritems[0].username,
              itemtex: arritem,
              order_out: typeout,
              order_thaiout: itemstatus,
              Service_fee: this.deliverypay,
              Shipping_cost: this.sumshipping,
              receipt: this.receipt,
              promotion: null,
              coupon: null,
              difference: differences,
            }
            if (this.couponitem) {
              const arr = {
                // eslint-disable-next-line no-underscore-dangle
                id_coupon: this.couponitem._id,
                old_payment: this.sumpayment,
                discount_coupon: this.couponitem.discount,
                coupon_name: this.couponitem.name,
              }
              obj.coupon = arr
            }
            if (this.itempro) {
              const arr = {
                // eslint-disable-next-line no-underscore-dangle
                id_pro: this.itemshipping._id,
                discount_pro: this.itemshipping.delivery,
                old_delivery: this.itemshipping.old_delivery,
                promotionname: this.itemshipping.name,
                // eslint-disable-next-line no-underscore-dangle
                id_promotion: this.itempro._id,

              }
              obj.promotion = arr
            }
            if (this.selectedProvince) {
              const provi = this.itemspvc.find(ele => (ele.province_id === this.selectedProvince))
              obj.address.province_name = provi.province_name
            }
            if (this.selectedDistrict) {
              const arr = this.idistrict.find(ele => (ele.district_id === this.selectedDistrict))
              obj.address.district = arr.district_name
            }
            if (this.selectedSubDistrict) {
              const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.selectedSubDistrict))
              obj.address.subdistrict = arr.sub_district_name
            }
            this.$http.post('/Paymentuser/store', obj)
              .then(() => {
                this.Success('บันทึกการชำระค่านำเข้าเรียบร้อย')
                this.$router.push({ name: 'admin-payment' })
                this.isDisabled = false
              })
              .catch(error => {
                this.SwalError(error)
                this.isDisabled = false
              })
            // console.log(obj)
          // console.log(formData)
          // this.$http
          //   .post('/users/Adminstore', formData)
          //   .then(() => {
          //     this.Success('เพิ่มผู้ใช้งาน')
          // this.role = null
          // this.email = null
          // this.fullname = null
          // this.tel = null
          // this.tel2 = null
          // this.different = null
          // this.invited_by = null
          // this.username = null
          // this.password = null
          // this.$router.push({ name: 'admin-employee' })
          // })
          // .catch(error => {
          //   this.SwalError(error.response.data.message)
          // })
          }
        })
      }
    },
    CommasCue(x) {
      if (!x) {
        return '0'
      }
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      if (parts[1]) {
        parts[1] = parseFloat(`0.${parts[1]}`).toFixed(4).split('.')[1]
      } else {
        parts.push('0000')
      }
      return parts.join('.')
    },
    inputtransports() {
      if (this.productType.delivery_type === 1) {
        if (this.productType.destination === 1 && this.transports === 5) {
          this.deliverymoney = 0
        } else if (this.productType.delivery_source === 1 && this.transports === 4) {
          const weight = this.Arritems.reduce((total, ele) => total + (ele.weight * ele.product_amount), 0)
          let maxPrice = 0
          if (this.productType.rate.length) {
            this.productType.rate.forEach(ele => {
              if (ele.min <= weight && ele.price > maxPrice) {
                maxPrice = ele.price
              }
            })
            this.deliverymoney = maxPrice
          } else {
            this.deliverymoney = 0
          }
        } else {
          this.deliverymoney = 0
        }
      }
      if (this.productType.delivery_type === 2) {
        if (this.productType.destination === 1 && this.transports === 5) {
          this.deliverymoney = 0
        } else if (this.productType.delivery_source === 1 && this.transports === 4) {
          const charge_cue = this.Arritems.reduce((total, ele) => total + (ele.charge_cue * ele.product_amount), 0)
          let maxPrice = 0
          if (this.productType.rate.length) {
            this.productType.rate.forEach(ele => {
              if (ele.min <= charge_cue && ele.price > maxPrice) {
                maxPrice = ele.price
              }
            })
            this.deliverymoney = maxPrice
          } else {
            this.deliverymoney = 0
          }
        } else {
          this.deliverymoney = 0
        }
      }
      if (this.WarehousePrice) {
        const checkID = this.WarehousePrice.free_shipping.find(ele => ele._id == this.productType._id)
        if (checkID) {
          this.deliverypay = 0
        } else {
          const weight = this.Arritems.reduce((total, ele) => total + (ele.weight * ele.product_amount), 0)
          let maxPrice = 0
          this.WarehousePrice.rate.forEach(ele => {
            if (ele.weight <= weight && ele.price > maxPrice) {
              maxPrice = ele.price
            }
          })
          this.deliverypay = maxPrice
        }
      }

      this.bydelivery = `${this.productType.name}`
      // this.productType.rate.forEach(ele => {
      //   if (ele.min < this.Arritems.length) {
      //     this.deliverymoney = ele.price
      //   }
      // })
      const params = {
        // eslint-disable-next-line no-underscore-dangle
        productType: this.productType._id,
        uid: this.ID,
      }
      this.$http.get('/Paymentuser/getPromotion', { params })
      // this.$http.get('/Paymentuser/getPromotion', { obj })
        .then(response => {
          this.proItems = []
          this.sumshipping = 0
          this.itempro = null
          this.itemshipping = []
          this.proItems = response.data.data
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    submitpro() {
      this.itempro = this.setDefault
      // eslint-disable-next-line eqeqeq, no-underscore-dangle
      this.itemshipping = this.setDefault.shippingType.find(item => item.id_delivery == this.productType._id)
    },
    addNewTex() {
      if (this.namefile == null) {
        this.SwalError('กรุณาแนบไฟล์')
      } else if (this.typetex === 0) {
        this.SwalError('กรุณาเลือกประเภท')
      } else {
        this.$refs.addNewtexse.validate().then(success => {
          if (success) {
            const obj = {
              id: this.idtex,
              uid: this.ID,
              typetex: this.typetex,
              texfullname: this.texfullname,
              address: this.addresstex,
              telephone: this.telephone,
              texnumber: this.Taxpayer,
              filetex: this.namefile,
            }
            this.$http.post('/Paymentuser/storeupdatetex', obj)
              .then(() => {
                this.getTexpay()
                this.Success('เพิ่มข้อมูลผู้เสียภาษีเรียบร้อย')
                this.$bvModal.hide('modal-receive-add-amend')
              })
              .catch(e => {
                this.SwalError(e)
              })
          }
        })
      }
    },
    filetex(event) {
      this.selectfile = event.target.files
      this.namefile = null
      const reader = new FileReader()
      const filepath = this.selectfile[0]
      reader.onload = e => {
        // console.log(e.target.result)
        this.namefile = e.target.result
        // console.log(this.namemap)
      }
      reader.readAsDataURL(filepath)
    },
    async getgeneral() {
      try {
        const { data: res } = await this.$http.get('/general/show?agent_id=1')
        this.general = res.data.service_fee_change_sender
      } catch (e) {
        console.log(e)
      }
    },
    checktypetex(item) {
      if (item === 1) {
        return 'บุคคลธรรมดา'
      } if (item === 2) {
        return 'นิติบุคคล'
      } return ''
    },
    checkreceipt(item) {
      if (item) {
        if (this.itemsTaxpayer) {
          // eslint-disable-next-line array-callback-return
          this.itemsTaxpayer.find(ele => {
            if (ele.isSelected) {
              // eslint-disable-next-line no-param-reassign
              ele.isSelected = false
            }
          })
        }

        // console.log(this.itemsTaxpayer)
      }
    },
    Deletetex(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบที่อยู่ใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(result => {
          if (result) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
            }
            this.$http.post('/Paymentuser/deletetex', params).then(() => {
              this.getTexpay()
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    Deleteaddress(item) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบที่อยู่ใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(result => {
          if (result) {
            const params = {
              id: item.id,
            }
            this.$http.post('/Paymentuser/deleteaddress', params).then(() => {
              this.getAddress()
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    Deletepro(item) {
      // eslint-disable-next-line no-param-reassign
      item.setDefault = false
      this.itempro = null
      // this.sumshipping = this.deliverymoney
      this.itemshipping = []
      // console.log(item)
    },
    Deletecoupon() {
      this.couponitem = null
      this.couponcheck = null
    },
    transportsnew(item) {
      this.productType = null
      this.bydelivery = null
      this.itempro = null
      this.itemshipping = []
      this.sumshipping = 0
      this.deliverymoney = 0
      // console.log(this.itempro)
      if (item === 1) {
        this.bydelivery = 'รับสินค้าเอง'
        this.deliverymoney = 0
        this.deliverypay = 0
        this.sumshipping = 0
        this.delivery = 0
        this.transports = 0
      } else if (item === 2) {
        this.bydelivery = 'บริษัทจัดส่ง 690 บาท'
        this.deliverymoney = 690
        this.deliverypay = 0
        this.delivery = 0
        this.sumshipping = 0
        this.transports = 0
      } else if (item === 3) {
        this.bydelivery = '-'
        this.deliverymoney = 0
        this.deliverypay = 0
        this.delivery = 0
        this.sumshipping = 0
        this.transports = 0
      } else {
        this.deliverypay = 0
        this.sumshipping = 0
        this.deliverymoney = 0
      }
      if (item === 4 || item === 5) {
        // const arritem = this.deliverypay
        this.deliverypay = 0
        this.delivery = 0
        this.sumshipping = 0
      }
      // console.log(item)
      // if (item === 4) {
      //   console.log(this.productType)
      // }
    },
    // async getPromotion() {
    //   try {
    //     const { data: res } = await this.$http.get('promotion/promotion_showall')
    //     console.log(res.data)
    //     this.proItems = res.data
    //     // console.log(res)
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    addAddress() {
      this.fullnamenew = this.checktext.fullname
      this.addressnew = this.checktext.address
      this.selectedProvince = this.checktext.province_id
      this.selectedDistrict = this.checktext.district_id
      this.selectedSubDistrict = this.checktext.subdistrict_id
      this.postalCode = this.checktext.postalcode
      this.telnumber = this.checktext.tel
    },
    async getproductType() {
      try {
        const { data: res } = await this.$http.get('/Paymentuser/getShippingType')
        this.rolelist = res
        // .then(response => {
        //   this.rolelist = response.data
        //   // console.log(response.data)
        // })
      } catch (e) {
        console.log(e)
      }
    },

    updateDistricts() {
      this.selectedDistrict = null
      this.selectedSubDistrict = null // อำเภอ
      this.postalCode = null
    },
    updateSubDistricts() {
      this.selectedSubDistrict = null
      this.postalCode = null // ตำบล
    },
    updatePostalcode() {
      this.postalCode = null
      const arr = this.izipcode.find(ele => (ele.tum_id === this.selectedSubDistrict))
      this.postalCode = arr.zipcode
    },
    updateDistrictsnew() {
      this.district = null
      this.subdistrict = null // อำเภอ
    },
    updateSubDistrictsnew() {
      this.subdistrict = null
      this.postal = null // ตำบล
    },
    updatePostalcodenew() {
      const arr = this.izipcode.find(ele => (ele.tum_id === this.subdistrict))
      this.postal = arr.zipcode
    },
    async getProvince() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/province') // จังหวัด
        this.itemspvc = res
      } catch (e) {
        console.log(e)
      }
    },
    async getDistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/district') // อำเภอ
        this.idistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getSubdistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/Subdistrict') // ตำบล
        this.iSubdistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getzipcode() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/zipcode') // ตำบล
        this.izipcode = res
      } catch (e) {
        console.log(e)
      }
    },
    submitcoupon() {
      this.$refs.coupon.validate().then(success => {
        if (success) {
          this.couponitem = this.couponcheck
        }
      })

      this.$bvModal.hide('modal-coupon')
    },
    EditType(item) {
      if (item) {
        // console.log(item)
        this.uidaddress = item.uid
        this.idaddress = item.id
        this.fullname = item.fullname
        this.phoneNum = item.tel
        this.address = item.address
        this.Province = item.province_id
        this.district = item.district_id
        this.subdistrict = item.subdistrict_id
        this.postal = item.postalcode
        this.setDefault = item.defaultget
      } else {
        this.fullname = null
        this.phoneNum = null
        this.address = null
        this.Province = null
        this.district = null
        this.subdistrict = null
        this.postal = null
        this.setDefault = null
      }
      this.$bvModal.show('modal-receive-add')
    },
    EditTex(item) {
      if (item) {
        this.$bvModal.show('modal-receive-add-amend')
        // eslint-disable-next-line no-underscore-dangle
        this.idtex = item._id
        this.typetex = item.typetex
        this.texfullname = item.texfullname
        this.addresstex = item.address
        this.telephone = item.telephone
        this.Taxpayer = item.texnumber
        this.namefile = item.filetex
      } else {
        this.typetex = 1
        this.texfullname = null
        this.addresstex = null
        this.telephone = null
        this.Taxpayer = null
        this.namefile = null
        this.idtex = null
        this.$bvModal.show('modal-receive-add-amend')
      }
      // this.$bvModal.show('modal-receive-add-amend')
    },
    handleFileChanges(event) {
      const { files } = event.target
      this.imagePreviewUrls = []
      // console.log(this.imagePreviewUrls)
      //   add to local storage
      localStorage.setItem(
        'imagePreviewUrls',
        JSON.stringify(this.imagePreviewUrls[0]),
      )

      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader()
        reader.onload = e => {
          this.imagePreviewUrls.push(e.target.result)
        }
        reader.readAsDataURL(files[i])
      }
    },
    addMoreImages() {
      this.$refs.fileInput.click()
      // console.log(this.imagePreviewUrls)
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      this.imgBackground = file
      this.previewImage(file)
    },
    previewImage(file) {
      const reader = new FileReader()
      reader.onload = () => {
        this.imagePreviewUrl = reader.result
      }
      reader.readAsDataURL(file)
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'warning' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-warning',
        },
      })
    },
    checkdelivery(item) {
      if (item === 0 || item === 1 || item === 2) {
        if (this.WarehousePrice && this.productType) {
          const checkID = this.WarehousePrice.free_shipping.find(ele => ele._id == this.productType._id)
          if (checkID) {
            this.deliverypay = 0
          } else {
            const weight = this.Arritems.reduce((total, ele) => total + (ele.weight * ele.product_amount), 0)
            let maxPrice = 0
            this.WarehousePrice.rate.forEach(ele => {
              if (ele.weight <= weight && ele.price > maxPrice) {
                maxPrice = ele.price
              }
            })
            this.deliverypay = maxPrice
          }
        } else {
          this.deliverypay = 0
        }
      } else if (item === 3) {
        if (this.WarehousePrice && this.productType) {
          const checkID = this.WarehousePrice.free_shipping.find(ele => ele._id == this.productType._id)
          if (checkID) {
            this.deliverypay = 0
          } else {
            const weight = this.Arritems.reduce((total, ele) => total + (ele.weight * ele.product_amount), 0)
            let maxPrice = 0
            this.WarehousePrice.rate.forEach(ele => {
              if (ele.weight <= weight && ele.price > maxPrice) {
                maxPrice = ele.price
              }
            })
            this.deliverypay = maxPrice
          }
        } else {
          this.deliverypay = 0
        }
        this.deliverypay += this.general
      } else if (this.WarehousePrice && this.productType) {
        const checkID = this.WarehousePrice.free_shipping.find(ele => ele._id == this.productType._id)
        if (checkID) {
          this.deliverypay = 0
        } else {
          const weight = this.Arritems.reduce((total, ele) => total + (ele.weight * ele.product_amount), 0)
          let maxPrice = 0
          this.WarehousePrice.rate.forEach(ele => {
            if (ele.weight <= weight && ele.price > maxPrice) {
              maxPrice = ele.price
            }
          })
          this.deliverypay = maxPrice
        }
      } else {
        this.deliverypay = 0
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style>
.btn-select {
  margin-top: 5px;
  padding: 1.5rem 2.5rem;
  border: 1px dashed #979797;
  border-radius: 14px;
  background-color: #c1ecfd;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
